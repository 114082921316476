import React, { useState, useEffect } from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Container, Wrapper, Box } from "../../components/util";
import { RequestAQuoteForm } from "../../components/forms";

const Quote = (props) => {
  const [quote, setQuote] = useState(false);

  useEffect(
    function () {
      if (props.location.search.substr(1) === "price") {
        setQuote(true);
      }
    },
    [setQuote]
  );

  return (
    <Layout>
      <Seo
        title="Get Started with Karmly | Pricing Quote & Demo"
        description="Have any questions? Here's how to get in touch with our friendly support & sales team."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80}>
          <Box stackGap={30}>
            <div className="-textCenter">
              <h1>{quote ? "Request a Quote" : "Get Started"}</h1>
              <h4>
                {quote
                  ? "Get a pricing quote for your specific business needs."
                  : "Fill out the form below and one of our team will get in touch."}
              </h4>
            </div>
            <div className="-center" css={{ maxWidth: "600px" }}>
              <RequestAQuoteForm
                submitButtonText={quote ? "Request a Quote" : "Get Started"}
              />
            </div>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Quote;
